<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row>
                  <b-col><h6 class="mb-0">Dữ liệu đối tác phản hồi</h6></b-col>
                </b-row>
              </template>
              <div class="filter-transaction">
                <div>
                  <div class="row mb-8">
                    <div class="col-lg-6 mb-lg-6 mb-6">
                      <label>Ngày đối soát:</label>
                      <div
                        class="input-daterange input-group"
                        id="kt_datepicker"
                      >
                        <input
                          type="date"
                          v-model="filter.date_from"
                          class="form-control datatable-input"
                          name="start"
                          placeholder="Từ"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 mb-lg-3mb-2">
                      <label>Hệ thống:</label>
                      <select v-model="filter.system" class="form-control">
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in systems"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-2 mb-lg-3mb-2">
                      <label>Đối tác:</label>
                      <select v-model="filter.partner" class="form-control">
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in partners"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-2 mb-lg-3mb-2">
                      <label>Chọn MID:</label>
                      <select v-model="filter.mid_code" class="form-control">
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in mid_codes"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-8">
                    <div class="col-lg-12 text-right">
                      <button
                        class="btn btn-primary btn-primary--icon"
                        @click="handleFilter"
                      >
                        <span>
                          <i class="la la-search" aria-hidden="true"></i>
                          <span>Tìm kiếm</span>
                        </span>
                      </button>
                      <button
                        class="ml-2 btn btn-secondary btn-secondary--icon"
                        @click="cancelFilter"
                      >
                        <span>
                          <i class="fa fa-ban" aria-hidden="true"></i>
                          <span>Huỷ</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <b-table
                  :fields="fields"
                  :items="items"
                  details-td-class="datatable-cell"
                  hover
                  table-class="datatable-table"
                  tbody-tr-class="datatable-row"
                  thead-class="datatable-head"
                  thead-tr-class="datatable-row"
                >
                  <template #cell(stt)="data">
                    <div>
                      {{ data.index + 1 }}
                    </div>
                  </template>
                  <template #cell(type)>Xử lý (XL) </template>
                  <template #cell(action)="data">
                    <a :href="`${data.value.detail}`" target="_blank">Chi tiết</a>
                    <br />
                    <a
                        href="#"
                        v-if="data.item.file_path"
                        @click="download(data.item.file_path)"
                    >Download</a
                    >
                    <br />
                  </template>
                </b-table>
                <div class="mt-3">
                  <b-pagination-nav
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    use-router
                    first-number
                    last-number
                    align="right"
                  ></b-pagination-nav>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
    <export-modal :modal-id="modalId"></export-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const CmsRepository = RepositoryFactory.get("cms");

export default {
  name: "Reconcile",
  mixins: [Common],
  components: {
    AlertDialogue,
    ExportModal,
  },
  data() {
    return {
      filter: {
        date_from: null,
        date_to: null,
        mid_code: "",
        partner: "",
        system: "",
      },
      partners: [
        { value: "NAPAS", name: "NAPAS" },
        { value: "MB", name: "MB" },
        { value: "VPB", name: "VPB" },
        { value: "BIDV", name: "BIDV" },
        { value: "VCB", name: "VCB" },
        { value: "ONEPAY", name: "ONEPAY" },
        { value: "9PAY", name: "9PAY" },
        { value: "PAYGATE", name: "PAYGATE" },
        { value: "VCCB", name: "BVB" },
        { value: "MSB", name: "MSB" },
        { value: "VPB_CYBS", name: "VPB_CYBS" },
        { value: "NAPAS_CASHOUT", name: "NAPAS_CASHOUT" },
        { value: "STB_CYBS", name: "STB_CYBS" },
      ],
      systems: [
        { value: "WALLET", name: "Ví điện tử" },
        { value: "PAYGATE", name: "Cổng thanh toán" },
      ],
      mid_codes: [
        { value: "MID_Napas_1", name: "MID_Napas_1" },
        { value: "MID_Napas_2", name: "MID_Napas_2" },
        { value: "MID_Napas_3", name: "MID_Napas_3" },
      ],
      modalId: "test-export",
      id: null,
      fields: [
        {
          key: "stt",
          label: "STT",
        },
        {
          key: "project",
          label: "Hệ thống",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date",
          label: "Ngày phản hồi",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "type",
          label: "Loại file",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "partner",
          label: "Tên đối tác",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "mid_code",
          label: "MID",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "transaction_file_response_count",
          label: "Tổng giao dịch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_file",
          label: "Thời điểm nhận file",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Thao tác",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.getList();
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dữ liệu đối tác phản hồi" },
    ]);
    this.filter = this.$route.query;

    this.cancelFilter();
  },
  methods: {
    async download(filePath) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.downloadFile(filePath);
        this.$bus.$emit("show-loading", false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.notifyAlert("success", "Đang tiến hành tải xuống file của bạn");
      } catch (e) {
        this.notifyAlert("warn", "File không tồn tại, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
    },
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;
        let response = await CmsRepository.getResponseAggregate(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        this.items = body.data.data;

        if (this.items.length) {
          this.items.forEach((value) => {
            value["action"] = {
              detail: `#/auto-reconcile/response/list/${value.id}?partner=${value.partner}&date_from=${value.date}`,
            };
          });
        }

        this.paginate.total = body.data.total;
        this.paginate.totalPage = body.data.last_page;
      } catch (e) {
        this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
    },
    handleFilter() {
      // router
      //   .push({
      //     path: "/auto-reconcile/response/aggregate",
      //     query: this.filter,
      //   })
      //   .catch(() => {});
      this.getList();
    },
    currentDate() {
      const date = new Date();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
    cancelFilter() {
      this.filter = {
        date_from: this.currentDate(),
        date_to: null,
        mid_code: "",
        partner: "",
        system: "",
      };
      this.getList();
    },
  },
};
</script>
